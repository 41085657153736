import { axiosClient, axiosSSOConfigPrivate } from '../../rest-client';
import {
  API_CALC_CREATE_TEMPLATE,
  API_CALC_GET_DRAFT_BY_ID,
  API_CALC_GET_DRAFTS_LIST,
  API_CALC_REPLACE_DRAFT,
  API_CALC_UPDATE_DRAFT,
  API_CALC_COMPLETE_DRAFT,
  API_CALC_UPDATE_ANSWERS,
  API_CALC_GET_ANSWERS,
  API_CALC_PRELIMINARY,
  API_CALC_GET_COST,
  API_CALC_GET_QUICK_COST,
} from './urls';

const transformDraft = (draft) => ({
  ...draft,
  type: draft?.calculatorType?.code,
  periodReportDate: draft.periodReportDate != null ? new Date(draft.periodReportDate) : null,
  periodReportDateEnd:
    draft.periodReportDateEnd != null ? new Date(draft.periodReportDateEnd) : null,
});

export const getDraftById = async (params) => {
  const transformResponse = ({ data, success }) => ({
    data: transformDraft(data),
    success,
  });

  return axiosClient.get(
    API_CALC_GET_DRAFT_BY_ID,
    axiosSSOConfigPrivate({}, { params: params, shouldTransformResponse: transformResponse })
  );
};

export const getCompanyDrafts = async (params, headers) => {
  const transformResponse = ({ data, success }) => ({
    data: data.map(transformDraft),
    success,
  });
  return axiosClient.get(
    API_CALC_GET_DRAFTS_LIST,
    axiosSSOConfigPrivate(headers, { params: params, shouldTransformResponse: transformResponse })
  );
};

export const createNewDraft = async (params, headers) =>
  axiosClient.post(API_CALC_CREATE_TEMPLATE, params, axiosSSOConfigPrivate(headers));

export const replaceDraft = async (params, headers) =>
  axiosClient.post(API_CALC_REPLACE_DRAFT, params, axiosSSOConfigPrivate(headers));

export const updateDraft = async (params, headers) => {
  const transformResponse = ({ data, success }) => ({
    data: transformDraft(data),
    success,
  });

  axiosClient.post(
    API_CALC_UPDATE_DRAFT,
    params,
    axiosSSOConfigPrivate(headers, { shouldTransformResponse: transformResponse })
  );
};

export const completeDraft = async (params, headers) =>
  axiosClient.post(API_CALC_COMPLETE_DRAFT, params, axiosSSOConfigPrivate(headers));

export const updateCalcAnswer = async (params, headers) =>
  axiosClient.post(API_CALC_UPDATE_ANSWERS, params, axiosSSOConfigPrivate(headers));

export const getCalcAnswers = async (params, headers) =>
  axiosClient.get(API_CALC_GET_ANSWERS, axiosSSOConfigPrivate(headers, { params: params }));

export const preliminaryAssessment = async (params, headers) =>
  axiosClient.post(API_CALC_PRELIMINARY, params, axiosSSOConfigPrivate(headers));

export const getCost = async (params, headers) =>
  axiosClient.get(API_CALC_GET_COST, axiosSSOConfigPrivate(headers, { params: params }));

export const getQuickCost = async (params, headers) =>
  axiosClient.get(API_CALC_GET_QUICK_COST, axiosSSOConfigPrivate(headers, { params: params }));
